import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const DataCollectionInfoTable = props => {
    const getData = () => {
        let rows = [];
        const { deploymentList } = props.data.flows;
        const siteMap = {};
        // categorize by site - row per site
        for (var cycle of deploymentList) {
            const siteId = parseInt(cycle.siteId, 10);
            const siteName = props.groups.find(g => g.group.groupId === siteId).group.label;

            const responseRateCount = cycle.respondentCount - cycle.invalid;
            if (siteMap[siteName]) {
                siteMap[siteName].notStarted += responseRateCount - cycle.numCompleted - cycle.optedOut;
                siteMap[siteName].incomplete += cycle.incomplete;
                siteMap[siteName].numCompleted += cycle.numCompleted;
                siteMap[siteName].numOptOut += cycle.optedOut;
            } else {
                siteMap[siteName] = {
                    notStarted: responseRateCount - cycle.numCompleted - cycle.optedOut,
                    incomplete: cycle.incomplete,
                    numCompleted: cycle.numCompleted,
                    numOptOut: cycle.optedOut,
                };
            }
        }
        for (const s of Object.keys(siteMap)) {
            rows.push({
                site: s,
                ...siteMap[s],
            });
        }
        return rows;
    };

    const getTotal = td => {
        const total = td.reduce(
            (acc, curr) => {
                acc.notStarted += curr.notStarted;
                acc.incomplete += curr.incomplete;
                acc.numCompleted += curr.numCompleted;
                acc.numOptOut += curr.numOptOut;
                return acc;
            },
            {
                notStarted: 0,
                incomplete: 0,
                numCompleted: 0,
                numOptOut: 0,
            }
        );
        total.site = 'Total:';
        return total;
    };

    const cols = [
        { name: 'site', label: 'Site', sort: true, filter: true },
        { name: 'notStarted', label: 'Number of Forms Not Started' },
        { name: 'incomplete', label: 'Number of Forms Incomplete' },
        { name: 'numCompleted', label: 'Number of completed DART forms' },
        { name: 'numOptOut', label: 'Number of Forms Opt-out' },
    ];

    const tableData = getData();

    return (
        <Table
            data={tableData}
            footer={getTotal(tableData)}
            rowKey="site"
            columns={cols}
            perPage={5000}
            perPageOptions={[25, 50, 100, 500]}
            isCsvDownload
            csvFilename="data-collection-info"
        />
    );
};

DataCollectionInfoTable.propTypes = {
    data: PropTypes.object,
    groups: PropTypes.array,
};

export default DataCollectionInfoTable;
