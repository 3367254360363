import React from 'react';
import PropTypes from 'prop-types';
import { mean, round } from 'lodash';
import { ResponsiveBar } from 'cccisd-nivo/bar';

function SubscaleItemChart(props) {
    const { data, subscale, groupLevelMap } = props;

    const getData = () => {
        const rows = [];
        for (const group of Object.keys(groupLevelMap)) {
            const row = { groupLevel: group };
            for (const item of subscale.items) {
                const groupSiteItemAverages = [];
                let groupItemN = 0;
                if (data.flows[item]) {
                    for (const site of data.flows[item].frequency) {
                        if (groupLevelMap[group].includes(parseInt(site.value, 10))) {
                            if (site.count > 0) {
                                groupSiteItemAverages.push(site.avg);
                                groupItemN += site.count;
                            }
                        }
                    }
                }
                row[item] = {
                    avg: mean(groupSiteItemAverages),
                    count: groupItemN,
                };
            }
            rows.push(row);
        }
        const totalRow = {};
        for (const row of rows) {
            for (const key of Object.keys(row)) {
                if (key !== 'groupLevel' && row[key].avg >= 0) {
                    if (totalRow[key]) {
                        totalRow[key].push(row[key].avg);
                    } else {
                        totalRow[key] = [row[key].avg];
                    }
                }
            }
        }
        const bars = [];
        for (const k of Object.keys(totalRow)) {
            bars.push({
                label: k,
                average: round(mean(totalRow[k]), 2) || 0,
            });
        }
        return bars;
    };

    const chartData = getData();

    return (
        <div style={{ width: '770px', height: '500px' }}>
            <ResponsiveBar
                indexBy="label"
                maxValue={2}
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                label={d => (d.value === 0 ? <tspan y="-15">{d.value}</tspan> : d.value || ' ')}
                labelSkipWidth={0}
                labelSkipHeight={0}
                keys={['average']}
                theme={{ fontSize: 16 }}
                axisBottom={{ tickRotation: 45 }}
                data={chartData}
                margin={{ top: 15, right: 150, bottom: 50, left: 50 }}
            />
        </div>
    );
}

SubscaleItemChart.propTypes = {
    data: PropTypes.object,
    subscale: PropTypes.object,
    groupLevelMap: PropTypes.object,
};

export default SubscaleItemChart;
